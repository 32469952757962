$clientDetailsContentLayout: (
  style: grid-template-columns,
  default: 1fr,
  md: 1fr 2fr
);

$clientDetailsContentEqualLayout: (
  style: grid-template-columns,
  md: 1fr 1fr
);

.clientDetailsPage {
  &ContentTop,
  &ContentBottom {
    @include generateResponsiveStyle($clientDetailsContentLayout);
    display: grid;
    column-gap: 2.7rem;
    row-gap: 2rem;
  }

  &ContentTop {
    align-items: center;
    @include generateResponsiveStyle($clientDetailsContentEqualLayout);
  }

  &ClientData {
    padding: 1.9rem 1.5rem 1.3rem;
    border-radius: .3rem;

    p {
      @include font($font-size: 2.2rem, $font-weight: map_get($fontWeights, medium), $color: $defaultColor);
      align-items: flex-start;
      font-size: map_get($fontSizes, default);
      min-height: 31px;

      &.title {
        @include font($font-size: 2.2rem, $font-weight: map_get($fontWeights, medium));
        word-break: break-word;
      }

      &.hasSeparator {
        border-bottom: .1rem solid $defaultColor;
      }
    }

    div {
      &.hasRightSeparator {
        border-right: 0.2rem solid $defaultColor;
      }

      &.hasDivSeparator {
        border-bottom: .1rem solid $defaultColor;

        span {
          color: $defaultColor;
          font-weight: map-get($fontWeights, medium);
        }
      }
    }
  }

  &ClientActionsList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2.5rem;
    row-gap: 2rem;

    .btnOutline {
      font-weight: map-get($fontWeights, medium);
      color: $primaryColor;
      border: .1rem solid $primaryColor;
      padding: 1rem;

      &:hover {
        color: $defaultColor;

        svg {
          color: $defaultColor;
        }
      }

      svg {
        color: $primaryColor;
      }
    }

    .btnDisabled:hover {
      background-color: $defaultColor !important;
      color: $secondaryColorDarker;
    }
  }

  &LocationInfo {
    @include font($font-size: map_get($fontSizes, default), $color: $primaryColor);
    @include position(absolute, null, 3rem, 3rem, 3rem);
    align-items: flex-end;
    background: $defaultColor;
    box-shadow: $navbarBoxShadow;
    z-index: 999;

    .clientUserDistance {
      margin-left: auto;

      span {
        &:first-child {
          @include font($font-size: map_get($fontSizes, md), $font-weight: map_get($fontWeights, bold));
        }

        &:last-child {
          opacity: .7;
        }
      }
    }
  }

  &Events {
    &Content {
      height: 29.8rem;
      overflow: auto;

      @include respond-to-min(md) {
        height: 63rem;
      }

      &.extraPadding {
        padding-right: .4rem;
      }

      &::-webkit-scrollbar {
        width: .4rem;
        margin-left: 1rem;
      }

      &::-webkit-scrollbar-track {
        background-color: $neutralDarker;
        border-radius: .1rem;
      }

      &::-webkit-scrollbar-thumb {
        background: $secondary;
        border-radius: .1rem;
      }
    }

    &SectionTitle {
      @include font($font-size: map_get($fontSizes, md), $color: $primaryColor);
    }
  }
}

.noVisitReportImages {
   .clientDetailsPage {
     &Events {
       &ListWrapper {
         @include respond-to-min(md) {
           height: 40rem;
         }
       }

       &Content {
         @include respond-to-min(md) {
           height: 23rem;
         }
       }
     }
   }
 }

.clientEventCard {
  color: $primaryColor;
  padding-bottom: .8rem;
  border-bottom: .1rem solid $primaryColorDark;

  &.futureEvent {
    background-color: $neutralLighter;
    border-radius: .5rem .5rem 0 0;

    .clientEventCardDate {
      display: block;
      position: relative;
      padding: .4rem 1rem;
      text-align: right;
      background-color: $primaryColorLightestTransparent;
      border-radius: .5rem .5rem 0 0;

      &:before {
        @include position(absolute, $left: .8rem);
        @include size(1.5rem, 2.5rem);
        content: '';
        background: url("#{$nextEventIcon}") no-repeat center;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  &Date {
    font-size: map_get($fontSizes, xs);
    display: inline-block;
    background-color: $neutralLighter;
    padding: .5rem .8rem;
  }

  &Type {
    @include font($font-size: 1.6rem, $font-weight: map_get($fontWeights, medium), $color: $primaryColor);
    padding: 0 .8rem;
  }

  &ClientName {
    padding: 0 .8rem;
    align-items: center;
  }
}

.imageContainer {
  max-height: 24rem;

  img {
    display: block;
    margin: 0 auto 10px;
    max-height: 100%;
    max-width: 100%;
  }
}
