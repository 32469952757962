$contractsGridLayout: (
  style: grid-template-columns,
  default: 1fr,
  md: 1fr 1fr,
);

.contractType {
  &List {
    @include generateResponsiveStyle($contractsGridLayout);
    display: grid;
    grid-gap: 1.5rem 3rem;
  }
}

.mixedFormType {
  border-color: $dangerColor;

  &List {
    @include generateResponsiveStyle($contractsGridLayout);
    display: grid;
    grid-gap: 1.5rem 3rem;
  }
}

.contractForm {
  .hasBorder {
    @include respond-to-min(md) {
      border-right: 0.1rem solid $neutralDark;
    }
  }
}

.contractName {
  flex-grow: 1;
}

.contractNo {
  &Status {
    @include border(
      $border-width: 2px,
      $border-style: solid,
      $border-color: $dangerColor,
      $border-radius: 50%
    );
    @include font($color: $dangerColor);
    height: 3.2rem;
    line-height: 3rem;
    min-width: 3.2rem;

    &.active {
      @include font($color: $secondaryColorLight);
      border-color: $secondaryColorLight;
    }
  }
}

.fileToDownloadWrapper {
  object {
    width: 80%;
    height: 100%;
  }
}

.signatureContentWrapper {
  max-width: 40rem;
}

.contractWarning {
  @include font($font-size: map_get($fontSizes, sm), $font-weight: map_get($fontWeights, bold), $color: $customColor7);
  @include respond-to-min(md) {
      width: 40rem;
      padding-top: 0;
      padding-bottom: 0;
  }
  padding-top: 1rem;
  padding-bottom: 1rem;
}