.form {
  &Type {
    @include font(
      $font-size: map_get($fontSizes, md),
      $font-weight: map_get($fontWeights, bold),
      $color: $secondaryColorDarker,
      $text-transform: uppercase
    );
    word-wrap: break-word;
  }
}

.reclamationForm {
  label {
    margin-bottom: 1rem;
  }
}

.destructionCertificateForm {
  &Wrapper {
    .firstSection {
      .label {
        @include font($font-size: map_get($fontSizes, md), $font-weight: map_get($fontWeights, bold), $color: $secondary, $text-transform: uppercase);
      }

      .address {
        border-bottom: .1rem solid $neutralDark;
      }

      .products {
        table {
          width: 100%;

          tr, th {
            border-bottom: .2rem solid $neutralDark;

            td {
              padding: 1rem;
              border-left: .2rem solid $neutralDark;

              &.col {
                &Comment {
                  width: 20rem;
                }

                &Lot, &Quantity {
                  width: 8rem;
                }

                &ExpDate {
                  width: 18rem;
                }

                &Actions {
                  width: 20rem;
                }
              }
            }

            & > td:first-child {
              border-left: none;
            }
          }

          tbody {
            tr {
              border-top: .1rem solid $neutralDark;
              border-bottom: none;
            }

            .newProductRow {
              td {
                border: none
              }
            }
          }
        }

        .btn {
          &Delete {
            background: $dangerColor url('#{$trashIconWhite}') no-repeat center;
            height: 2.5rem;

            &:hover {
              background: $dangerColorLight url('#{$trashIconWhite}') no-repeat center;
            }
          }

          &Add {
            background: $secondaryColorLight url('#{$plusIconWhite}') no-repeat center;
            color: $defaultColor;
            height: 2.5rem;

            &:hover {
              background: $primaryColor url('#{$plusIconWhite}') no-repeat center;            }
          }
        }
      }
    }

    .secondSection {
      .mediaSection {
        border-top: 0.1rem solid #2973804C;
        padding-top: 2.5rem;
        border-bottom: 0.1rem solid #2973804C;
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }
  }
}

.errorMessage {
  @include font($font-weight: map-get($fontWeights, regular));
}

.signature {
  &Wrapper {
    height: 114px;
  }

  &Canvas {
    height: 100%;
    width: 100%;
  }
}

.editor {
  &Wrapper {
    border: $inputBorder;
    border-radius: $inputBorderRadius;
  }
}

.commentWrapper {
  list-style-position: inside;
  word-break: break-word;
}

.rangeInput {
  &Thumb {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    box-shadow: $inputBoxShadow;
  }

  &Output {
    @include position($bottom: 100%);
  }
}

.newVisitReportForm {
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 230px);

  .react-tabs__tab-panel {
    flex-grow: 1;
  }

  .actionButtons {
    .radioButtonsWrapper {
      column-gap: 1rem;

      @include respond-to-min(lg) {
        column-gap: 2rem;
      }
    }
  }
}

.submitVisitReport {
  text-transform: none;
}

.react-tabs__tab--selected {
  .disabledIcon {
    display: none;
  }
}

.isClearable  {
  .react-datepicker {
    &__close-icon {
      left: 0;
      right: initial;

      &:after {
        @extend %fa-icon;
        @extend .fa;

        background: transparent;
        border-radius: initial;
        content: fa-content($fa-var-trash-can);
        color: $dangerColor;
        font-size: 1.8rem;
        height: 2rem;
        width: 2rem;
      }
    }

    &__input-container {
      padding-left: 3rem;
    }
  }

  &.withoutValue {
    .react-datepicker__close-icon:after {
      color: $primaryColorDark;
    }
  }
}

.linearCalculationSection{
  width: fit-content;
}
