.pageFilters {
    &SimpleSearch {
    .inputLabel {
      display: none;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      @include font($color: $primaryColor, $font-style: italic);
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      @include font($color: $primaryColor, $font-style: italic);
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
      @include font($color: $primaryColor, $font-style: italic);
    }
  }

  &AdvancedCtaWrapper {
    .btnPrimary {
      @include on-event() {
        color: $secondaryColorLight;
      }
      color: $primaryColor;
      font-weight: map-get($fontWeights, medium);
      padding: 1.7rem 1rem;
      margin-right: 1rem;
    }
  }

  &SeeMapWrapper {
    margin-left: auto;
  }

  &ActiveSearch {
    @include display-flex($align-items: center);

    .filters {
      display: inline-block;
    }

    .filter {
      @include font(
              $font-weight: map-get($fontWeights, regular),
              $font-style: italic,
              $color: $primaryColor,
              $line-height: 2rem
      );
      padding: 0 .2rem;

      &-qualification1,
      &-qualification2 {
        text-transform: capitalize;
      }
    }

    .clearFilters {
      @include font(
              $font-weight: map-get($fontWeights, medium),
              $color: $primaryColor
      );

    }
  }
}


.dashboardFilters {
    &CtaRow {
      @include display-flex($justify-content: space-between);

      .btn {
        padding: 1.4rem;
      }
    }
}

.filtersSection {
  .react-datepicker__triangle {
    left: 50% !important;
  }
}

.groupIds .inputSearchField__value-container {
  padding: .5rem .8rem;
}
