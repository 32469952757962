$cardsGridLayout: (
  style: grid-template-columns,
  default: 1fr,
  md: 1fr 1fr
);

$clientNameFontSize: (
  style: font-size,
  default: 1.4rem,
  sm: 1.6rem,
  md: 1.8rem,
  lg: 2rem
);

$newAppointmentFontSize: (
  style: font-size,
  default: 1.4rem,
  lg: 1.6rem
);

$newAppointmentPadding: (
  style: padding,
  default: .7rem,
  md: .3rem,
  lg: .7rem
);

$clientTitleDisplay: (
  style: display,
  default: block,
  md: -webkit-box,
  lg: block
);

$clientTitleMaxHeight: (
  style: max-height,
  default: none,
  md: 4rem,
  lg: none
);

.clientDetails {
  &List {
    @include generateResponsiveStyle($cardsGridLayout);
    display: grid;
    grid-gap: 2rem;
  }

  &PageEventsContent.loading {
    overflow-y: hidden;
  }

  &Item {
    background: $defaultColor;
    border-radius: $basicBorderRadius;

    &Header {
      @include display-flex($justify-content: space-between);
      span {
        @include generateResponsiveStyle($clientNameFontSize);
      }
    }

    &Content {
      p {
        @include display-flex($align-items: center)
      }
    }

    &Footer {
      @include display-flex($justify-content: space-between);
      background: $neutral;
      padding: .7rem 0;
      border-radius: $basicBorderRadius;

      &Label {
        @include generateResponsiveStyle($newAppointmentFontSize);
        @include font(
          $font-weight: map-get($fontWeights, regular),
          $font-style: italic,
          $color: $primaryColor
        );
        padding: 0 .7rem;
      }

      &Cta {
        @include generateResponsiveStyle($newAppointmentFontSize);
        @include font(
          $font-weight: map-get($fontWeights, regular),
          $color: $primaryColor
        );
        @include on-event() {
          color: $primaryColor
        }
      }
    }
  }

  &Name {
    @include generateResponsiveStyle($clientNameFontSize);
    @include font(
      $font-weight: map-get($fontWeights, medium),
      $color: $secondary,
    );

    &Label {
      word-break: break-all;
    }
  }

  &Title {
    @include generateResponsiveStyle($clientTitleMaxHeight);
    @include generateResponsiveStyle($clientTitleDisplay);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &AppointmentCta {
    @include generateResponsiveStyle($newAppointmentFontSize);
    @include generateResponsiveStyle($newAppointmentPadding);
    @include font(
      $font-weight: map-get($fontWeights, medium),
      $text-transform: capitalize
    );
    max-width: 11.5rem;
    border-radius: $buttonBorderRadius;
  }
}
