.tooltip {
  &Tip {
    @include position(absolute, 100%, null, null, 50%);
    border-radius: 0.5rem;
    display: block;
    transform: translateX(-50%);
    width: 578px;
    z-index: 1000;

    &:before {
      @include position(absolute, null, null, 100%, 50%);
      border: 1rem solid transparent;
      border-bottom-color: $defaultColor;
      content: '';
      transform: translate(-0.5rem, 0.4rem);
    }

    ul {
      list-style-type: none;
    }
  }

  &Overlay {
    @include position(fixed, 0, 0, 0, 0);
    background: transparent;
    z-index: 5;
  }
}

.searchSection {
  .tooltip {
    &Tip {
      left: -150%;
    }
  }
}

.dot {
  border: 0.2rem solid $primaryColor;
  border-radius: 50%;
  display: inline-block;
  height: 1rem;
  width: 1rem;

  &.customColor {
    &Purple {
      background-color: $customColor3;
      border-color: $customColor3;
    }

    &Darkgreen {
      background-color: $primaryColor;
      border-color: $primaryColor;
    }

    &Yellow {
      background-color: $warningColor;
      border-color: $warningColor;
    }

    &Red {
      background-color: $customColor1;
      border-color: $customColor1;
    }

    &Darkred {
      background-color: $customColor2;
      border-color: $customColor2;
    }

    &Gray {
      background-color: $customColor4;
      border-color: $customColor4;
    }

    &Blue {
      background-color: $customColor5;
      border-color: $customColor5;
    }

    &Green {
      background-color: $customColor6;
      border-color: $customColor6;
    }

    &Pink {
      background-color: $defaultColor;
      border-color: $customColor8;
    }

    &Darkblue {
      background-color: $secondaryColorDarker;
      border-color: $secondaryColorDarker;
    }
  }
}

.toggle {
  padding-right: 4rem;
  position: relative;

  &:after {
    @include position(absolute, 0, 1.5rem, 0, null);
    background: url('#{$iconArrow}') no-repeat 98% center;
    content: '';
    transform: rotate(180deg);
    width: 1.5rem;
  }

  &.opened:after {
    transform: initial;
  }
}
