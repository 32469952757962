$reportTabsColumnTemplate: (
  style: grid-template-columns,
  default: repeat(1, 1fr),
  md: repeat(4, 1fr)
);

$historyTabsColumnTemplate: (
  style: grid-template-columns,
  default: repeat(2, 1fr),
  md: repeat(3, 1fr)
);

$tabsFontSize: (
  style: font-size,
  default: map_get($fontSizes, xs),
  md: map_get($fontSizes, default)
);

.tabsWrapper {
  .react-tabs {
    &__tab-list {
      display: grid;
      border-bottom: none;
    }

    &__tab {
      @include generateResponsiveStyle($tabsFontSize);
      @include font($color: $primaryColor, $font-weight: map_get($fontWeights, semi-bold), $text-align: center);
      transition: all .3s ease-in-out;
      padding: 1.8rem 3rem;
      box-shadow: $navbarBoxShadow;
      bottom: unset;
      border: none;
      border-bottom: .2rem solid transparent;

      span {
        opacity: .5;
      }

      &:focus {
        outline: none;
        box-shadow: $navbarBoxShadow;
        border-bottom: .2rem solid $secondaryColorLight;

        span {
          opacity: 1;
        }

        &::after {
          display: none;
        }
      }

      &--selected {
        border-radius: 0;
        border-bottom: .2rem solid $secondaryColorLight;

        span {
          opacity: 1;
        }
      }

      &-panel {
        min-height: 25rem;

        .loader {
          margin-top: 3rem;
        }
      }
    }
  }

  &.tabsReport {
    .react-tabs__tab-list {
      @include generateResponsiveStyle($reportTabsColumnTemplate);
    }
  }

  @for $i from 1 through $gridColumns {
    $reportTabsColumnTemplateDynamic: (
      style: grid-template-columns,
      default: repeat(1, 1fr),
      md: repeat($i, 1fr)
    );

    &.tabsReport#{$i} {
      .react-tabs__tab-list {
        @include generateResponsiveStyle($reportTabsColumnTemplateDynamic);
      }
    }
  }

  &.tabsHistory {
    .react-tabs__tab {
      &-list {
        @include generateResponsiveStyle($historyTabsColumnTemplate);
      }

      &-panel {
        overflow-x: auto;

        &::-webkit-scrollbar {
          height: .4rem;
          margin-left: 1rem;
        }

        &::-webkit-scrollbar-track {
          background-color: $neutralDarker;
          border-radius: .1rem;
        }

        &::-webkit-scrollbar-thumb {
          background: $secondary;
          border-radius: .1rem;
        }
      }
    }

    .recapPanel {
      &CtaWrapper {
        gap: 1.5rem;

        .btnOutline {
          font-weight: map_get($fontWeights, regular);
        }
      }
    }
  }
}

.alignRight {
  .radioButtonsWrapper {
    justify-content: flex-end;
  }
}

.noWrap {
  .radioButtonsWrapper {
    flex-wrap: nowrap !important;
  }
}
